import { Classifier } from '@fleet/shared/dto/classifier';

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  SALES_CHANNEL = 'SALES_CHANNEL',
  TICKET_PAYMENT_DEADLINE_TYPE = 'TICKET_PAYMENT_DEADLINE_TYPE',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  CARRIER = 'CARRIER',
  COUNTRY = 'COUNTRY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.CULTURE,
  ClassificationGroup.CURRENCY,
  ClassificationGroup.PRODUCT_CATEGORY,
  ClassificationGroup.SALES_CHANNEL,
  ClassificationGroup.TICKET_PAYMENT_DEADLINE_TYPE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface Country extends Classifier {
  areaPhoneCode?: string;
  code?: string;
  culture?: string;
  currency?: string;
  unixTimeZone?: string;
  vatRate?: string;
}

export interface BusinessEntity extends Classifier<number> {
  isMain: boolean;
  contactType: string;
}
