import { useMemo, FC } from 'react';
import { makeStyles } from '@mui/styles';
import { TimeToLive } from 'dto/timeToLive';
import { Card, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { Link } from 'react-router-dom';
import {
  TimeDurationLabel,
  TimeUnit,
  convertNumberToTimeDuration,
} from '@fleet/shared';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'block',
      padding: theme.spacing(2),
      color: 'inherit',
      textDecoration: 'none',
    },
    title: {},
  }),
  {
    name: 'TimeToLiveConfigurationCard',
  }
);

interface TimeToLiveConfigurationCardProps {
  ttl: TimeToLive;
}

export const TimeToLiveConfigurationCard: FC<TimeToLiveConfigurationCardProps> =
  ({ ttl }) => {
    const timeToLive = useMemo(
      () => convertNumberToTimeDuration(ttl.timeToLiveInMinutes),
      [ttl.timeToLiveInMinutes]
    );

    const classes = useStyles();

    return (
      <Card
        className={classes.root}
        variant="outlined"
        component={Link}
        to={`/ttl/edit/${ttl.id}`}
      >
        <Typography className={classes.title} variant="body1" color="inherit">
          <strong>{ttl.salesChannel.name}</strong>
        </Typography>
        <Stack direction="row" gap={1}>
          <span>
            <TransField i18nKey="timeToLive" />:
          </span>
          <TimeDurationLabel
            value={timeToLive}
            excludedUnits={[TimeUnit.SECONDS]}
          />
        </Stack>
      </Card>
    );
  };
