import {
  FormControl,
  FormField,
  FormFieldProps,
  useFormContext,
  useModal,
} from '@fleet/shared';
import {
  FinalFieldAdapterProps,
  metaError,
} from '@fleet/shared/form/FormField';
import { Icon } from '@fleet/shared/mui';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Classifier } from '@fleet/shared/dto/classifier';
import { TransButton } from 'i18n/trans/button';
import { ReactNode, useCallback } from 'react';
import { StopSelectionModal } from 'routes/TicketTexts/TicketTextStopField/StopSelectionModal';

interface TicketTextsStopFieldProps {
  label: ReactNode;
}

interface FareModelStopsSelectAdapterProps<TValue extends Classifier<number>>
  extends FinalFieldAdapterProps<TValue, HTMLDivElement>,
    Partial<TicketTextsStopFieldProps> {}

function TicketTextsStopFieldAdapter<TValue extends Classifier<number>>({
  label,
  input,
  meta,
}: FareModelStopsSelectAdapterProps<TValue>) {
  const { name, value } = input;
  const { open: isOpen, onOpen, onClose } = useModal();
  const form = useFormContext();

  const handleSubmit = useCallback(
    (selectedStop: Classifier<number>) => {
      form.change(name, selectedStop);
      onClose();
    },
    [form, name, onClose]
  );

  return (
    <>
      <FormControl label={label} error={metaError(meta)}>
        {value.id ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>{value.name}</Typography>
            <IconButton onClick={() => form.change(name)}>
              <Icon name="close" />
            </IconButton>
          </Stack>
        ) : (
          <Button
            style={{ paddingLeft: '0', justifyContent: 'flex-start' }}
            startIcon={<Icon name="search" />}
            onClick={onOpen}
          >
            <TransButton i18nKey="selectStop" />
          </Button>
        )}
      </FormControl>
      <StopSelectionModal
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </>
  );
}

export const TicketTextStopField = <TValue extends Classifier<number>>(
  props: FormFieldProps<TValue> & TicketTextsStopFieldProps
) => <FormField component={TicketTextsStopFieldAdapter} {...props} />;
