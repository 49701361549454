import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  bookingFee: <Trans i18nKey="title.bookingFee" defaults="Booking fee" />,
  selectStop: <Trans i18nKey="title.selectStop" defaults="Select stop" />,
  onHoldBookingConfigurations: (
    <Trans
      i18nKey="title.onHoldBookingConfigurations"
      defaults="On-hold booking configurations"
    />
  ),
  payConductorConfiguration: (
    <Trans
      i18nKey="title.payConductorConfiguration"
      defaults="Pay conductor configuration"
    />
  ),
  ticketTexts: <Trans i18nKey="title.ticketTexts" defaults="Ticket texts" />,
  timeToLive: <Trans i18nKey="title.timeToLive" defaults="Booking TTL" />,
});
