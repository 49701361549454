import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  carrier: <Trans i18nKey="table.head.carrier" defaults="Carrier" />,
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  currency: <Trans i18nKey="table.head.currency" defaults="Currency" />,
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  isMain: <Trans i18nKey="table.head.isMain" defaults="Is main" />,
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  placeholder: (
    <Trans i18nKey="table.head.placeholder" defaults="Placeholder" />
  ),
  productCategory: (
    <Trans i18nKey="table.head.productCategory" defaults="Product category" />
  ),
  shortDescription: (
    <Trans i18nKey="table.head.shortDescription" defaults="Short description" />
  ),
  salesChannel: (
    <Trans i18nKey="table.head.salesChannel" defaults="Sales channel" />
  ),
  streetHouseNumber: (
    <Trans
      i18nKey="table.head.streetHouseNumber"
      defaults="Street, house, number"
    />
  ),
  ticketPaymentDeadlineType: (
    <Trans
      i18nKey="table.head.ticketPaymentDeadlineType"
      defaults="Ticket payment deadline type"
    />
  ),
  ticketTextType: <Trans i18nKey="table.head.ticketTextType" defaults="Type" />,
  validFrom: <Trans i18nKey="table.head.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="table.head.validTo" defaults="Valid to" />,
});
