import { createAsyncThunk } from 'store/utils';
import { api } from '@fleet/shared';
import type { TimeToLive, TimeToLivePayload } from 'dto/timeToLive';
import { createAction } from '@reduxjs/toolkit';

export const getTTLConfigurations = createAsyncThunk(
  'timeToLive/getConfigurations',
  async (_, { getState }) => {
    const {
      common: { currentBusinessEntityId },
    } = getState();

    return (
      await api.get<{ items: Array<TimeToLive> }>(
        `/owners/${currentBusinessEntityId}/configuration/booking-time-to-live`
      )
    ).data.items;
  }
);

export const setTTLConfiguration = createAction<TimeToLive | undefined>(
  'timeToLive/setTTLConfiguration'
);
export const getTTLConfiguration = createAsyncThunk<TimeToLive, string>(
  'timeToLive/getConfiguration',
  async (id, { dispatch, getState }) => {
    const {
      common: { currentBusinessEntityId },
    } = getState();

    const { data } = await api.get<TimeToLive>(
      `/owners/${currentBusinessEntityId}/configuration/booking-time-to-live/${id}`
    );
    dispatch(setTTLConfiguration(data));
    return data;
  }
);

export const createTTLConfiguration = createAsyncThunk<
  TimeToLive,
  TimeToLivePayload
>(
  'timeToLive/createTTLConfiguration',
  async (payload, { dispatch, getState }) => {
    const {
      common: { currentBusinessEntityId },
    } = getState();

    const { data } = await api.post<TimeToLive>(
      `/owners/${currentBusinessEntityId}/configuration/booking-time-to-live`,
      payload
    );
    dispatch(setTTLConfiguration(data));
    return data;
  }
);

export const updateTTLConfiguration = createAsyncThunk<
  TimeToLive,
  TimeToLivePayload
>(
  'timeToLive/updateTTLConfiguration',
  async (payload, { dispatch, getState }) => {
    const {
      common: { currentBusinessEntityId },
      ttl: { current },
    } = getState();

    const { data } = await api.put<TimeToLive>(
      `/owners/${currentBusinessEntityId}/configuration/booking-time-to-live/${
        current!.id
      }`,
      payload
    );
    dispatch(setTTLConfiguration(data));
    return data;
  }
);

export const deleteTTLConfiguration = createAsyncThunk<void, undefined>(
  'timeToLive/updateTTLConfiguration',
  async (_, { dispatch, getState }) => {
    const {
      common: { currentBusinessEntityId },
      ttl: { current },
    } = getState();

    await api.delete<TimeToLive>(
      `/owners/${currentBusinessEntityId}/configuration/booking-time-to-live/${
        current!.id
      }`
    );
    await dispatch(getTTLConfigurations());
    dispatch(setTTLConfiguration(undefined));
  }
);
