import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import { TicketText, TicketTextFilter } from 'dto/ticketTexts';
import {
  clearTicketTexts,
  getTicketTexts,
  setTicketText,
  setTicketTextsFilter,
} from 'features/ticketText/ticketTextActions';

export interface CommonState {
  list?: Pagination<TicketText>;
  filter: Partial<TicketTextFilter>;
  current?: TicketText;
}

const initialState: CommonState = {
  filter: {},
};

export const ticketTextReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTicketTexts.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(clearTicketTexts, (state) => {
      state.list = undefined;
    })
    .addCase(setTicketText, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setTicketTextsFilter, (state, action) => {
      state.filter = action.payload;
    });
});
