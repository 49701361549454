import { classificationReducer } from 'features/classification/classificationReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { onHoldBookingConfigurationReducer } from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationReducer';
import { ticketTextReducer } from 'features/ticketText/ticketTextReducer';
import { ttlConfigurationReducer } from 'features/timeToLive/timeToLiveReducer';
import { bookingFeeReducer } from 'features/bookingFee/bookingFeeReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  common: commonReducer,
  onHoldBookingConfiguration: onHoldBookingConfigurationReducer,
  ticketText: ticketTextReducer,
  ttl: ttlConfigurationReducer,
  bookingFee: bookingFeeReducer,
});
