import { createSelector, selector } from 'store/utils';

export const selectTicketTexts = selector((state) => state.ticketText.list);
export const ticketTextsSelector = createSelector(selectTicketTexts);

export const selectTicketTextsFilter = selector(
  (state) => state.ticketText.filter
);
export const ticketTextsFilterSelector = createSelector(
  selectTicketTextsFilter
);

export const selectCurrentTicketText = selector(
  (state) => state.ticketText.current
);
export const currentTicketTextSelector = createSelector(
  selectCurrentTicketText
);
