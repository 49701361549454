import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import { BookingFee, BookingFeeFilter } from 'dto/bookingFee';
import {
  clearBookingFees,
  getBookingFees,
  setBookingFee,
  setBookingFeesFilter,
} from 'features/bookingFee/bookingFeeActions';

export interface CommonState {
  list?: Pagination<BookingFee>;
  filter: Partial<BookingFeeFilter>;
  current?: BookingFee;
}

const initialState: CommonState = {
  filter: {},
};

export const bookingFeeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBookingFees.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(clearBookingFees, (state) => {
      state.list = undefined;
    })
    .addCase(setBookingFee, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setBookingFeesFilter, (state, action) => {
      state.filter = action.payload;
    });
});
