import { FC } from 'react';
import {
  ConditionField,
  DateField,
  PeriodField,
  RadioGroupField,
  TextField,
  TimeDurationField,
  renderPercentageError,
  useFormContext,
} from '@fleet/shared';
import { Divider, Grid, Typography } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { NotificationTimesField } from 'routes/OnHoldBookingConfigurations/OnHoldBookingConfigurationFields/NotificationTimesField';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { TimeUnit } from '@fleet/shared/utils/timeDuration';

interface OnHoldBookingFormProps {}
export const OnHoldBookingFields: FC<OnHoldBookingFormProps> = () => {
  const { getState } = useFormContext();
  const ticketPaymentDeadlineTypeOptions = useClassificationOptions(
    ClassificationGroup.TICKET_PAYMENT_DEADLINE_TYPE
  );

  return (
    <>
      <Grid item xs={1}>
        <TextField name="name" label={<TransField i18nKey="name" />} required />
      </Grid>
      <PeriodField
        from={{
          name: 'validityPeriod.from',
          label: <TransField i18nKey="validFrom" />,
          required: true,
        }}
        to={{
          name: 'validityPeriod.to',
          label: <TransField i18nKey="validTo" />,
          required: true,
        }}
      />
      <Grid item xs={1}>
        <RadioGroupField
          name="ticketPaymentDeadlineTypeId"
          label={<TransField i18nKey="ticketPaymentDeadlineType" />}
          options={ticketPaymentDeadlineTypeOptions}
          inline
        />
      </Grid>
      <Grid item xs={1}>
        <ConditionField
          when="ticketPaymentDeadlineTypeId"
          is="TICKET_PAYMENT_DEADLINE_TYPE.PERCENTAGE_OF_TIME"
        >
          <Grid container columns={1} spacing={2} alignItems="flex-start">
            <Grid item xs={1}>
              <TextField
                name="timeUntilDeparturePercentage"
                label={
                  <TransField i18nKey="deadlinePercentOfTimeUntilDeparture" />
                }
                validate={renderPercentageError}
                type="number"
                endAdornment={<TransField i18nKey="percent" />}
                required
              />
            </Grid>
          </Grid>
        </ConditionField>
        <ConditionField
          when="ticketPaymentDeadlineTypeId"
          is="TICKET_PAYMENT_DEADLINE_TYPE.FIXED_TIME"
        >
          <TimeDurationField
            name="timeAfterBooking"
            label={<TransField i18nKey="deadlineTimeAfterBooking" />}
            excludedUnits={[TimeUnit.SECONDS]}
            required
          />
        </ConditionField>
      </Grid>
      <Grid item xs={1}>
        <TimeDurationField
          name="timeBeforeDeparture"
          label={<TransField i18nKey="deadlineTimeBeforeDeparture" />}
          excludedUnits={[TimeUnit.SECONDS]}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <NotificationTimesField />
      </Grid>
      <Grid item xs={1}>
        <DateField
          label={<TransField i18nKey="defaultPaymentDeadlineTime" />}
          name="defaultPaymentDeadlineTime"
          showTimeSelect
          showTimeSelectOnly
          dateFormat={currentLocaleConfiguration.timeFormat}
          timeFormat={currentLocaleConfiguration.timeFormat}
          timeIntervals={1}
          required={
            getState().values.ticketPaymentDeadlineTypeId ===
            'TICKET_PAYMENT_DEADLINE_TYPE.PERCENTAGE_OF_TIME'
          }
        />
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          label={<TransField i18nKey="isMain" />}
          name="isMain"
          options="BOOL_ONLY"
          inline
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mx: -3 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle">
          <TransTitle i18nKey="payConductorConfiguration" />
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <TimeDurationField
          name="bookingAvailableBeforeDeparture"
          label={<TransField i18nKey="bookingAvailableBeforeDeparture" />}
          excludedUnits={[TimeUnit.SECONDS]}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TimeDurationField
          name="deadlineTimeAfterDeparture"
          label={<TransField i18nKey="deadlineTimeAfterDeparture" />}
          excludedUnits={[TimeUnit.SECONDS]}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mx: -3 }} />
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          label={<TransField i18nKey="autoCancellation" />}
          name="useAutoCancellation"
          options="BOOL_ONLY"
          inline
        />
      </Grid>
    </>
  );
};
