import { createSelector, selector } from 'store/utils';

export const selectCurrentOnHoldBookingConfiguration = selector(
  (state) => state.onHoldBookingConfiguration.current
);
export const currentOnHoldBookingConfigurationSelector = createSelector(
  selectCurrentOnHoldBookingConfiguration
);

export const selectOnHoldBookingConfigurations = selector(
  (state) => state.onHoldBookingConfiguration.list
);
export const onHoldBookingConfigurationsSelector = createSelector(
  selectOnHoldBookingConfigurations
);

export const selectOnHoldBookingConfigurationsFilter = selector(
  (state) => state.onHoldBookingConfiguration.filter
);
export const onHoldBookingConfigurationsFilterSelector = createSelector(
  selectOnHoldBookingConfigurationsFilter
);
