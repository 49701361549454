import { createReducer } from '@reduxjs/toolkit';
import {
  getTTLConfigurations,
  setTTLConfiguration,
} from 'features/timeToLive/timeToLiveActions';
import type { TimeToLive } from 'dto/timeToLive';

export interface TTLConfigurationState {
  current?: TimeToLive;
  list: Array<TimeToLive>;
}

const initialState: TTLConfigurationState = {
  list: [],
};

export const ttlConfigurationReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getTTLConfigurations.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setTTLConfiguration, (state, action) => {
        state.current = action.payload;
      });
  }
);
