import { createAction } from '@reduxjs/toolkit';
import {
  OnHoldBookingConfiguration,
  OnHoldBookingConfigurationPayload,
  OnHoldBookingConfigurationSearchFormValues,
} from 'dto/onHoldBookingConfiguration';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { createAsyncThunk } from 'store/utils';
import { Pagination } from '@fleet/shared/dto/pagination';
import qs from 'qs';
import { api } from '@fleet/shared';

export const clearOnHoldBookingConfiguration = createAction(
  'onHoldBookingConfiguration/clearOnHoldBookingConfiguration'
);

export const setOnHoldBookingConfigurationFilter = createAction<
  Partial<OnHoldBookingConfigurationSearchFormValues>
>('onHoldBookingConfiguration/setOnHoldBookingConfigurationFilter');

export const clearOnHoldBookingConfigurations = createAction(
  'onHoldBookingConfiguration/clearOnHoldBookingConfigurations'
);

export const getOnHoldBookingConfigurations = createAsyncThunk<
  Pagination<OnHoldBookingConfiguration>,
  Partial<OnHoldBookingConfigurationSearchFormValues> | undefined
>(
  'onHoldBookingConfiguration/getOnHoldBookingConfigurations',
  async (values, { getState, dispatch }) => {
    values && dispatch(setOnHoldBookingConfigurationFilter(values));
    const {
      common: { currentBusinessEntityId },
      onHoldBookingConfiguration: { filter },
    } = getState();

    return (
      await api.get<Pagination<OnHoldBookingConfiguration>>(
        `/owners/${currentBusinessEntityId}/configuration/on-hold-booking${qs.stringify(
          filter,
          {
            addQueryPrefix: true,
            skipNulls: true,
            allowDots: true,
          }
        )}`
      )
    ).data;
  }
);

export const getOnHoldBookingConfiguration = createAsyncThunk<
  OnHoldBookingConfiguration,
  string
>(
  'onHoldBookingConfiguration/getOnHoldBookingConfiguration',
  async (id, { getState }) =>
    (
      await api.get(
        `/owners/${selectCurrentBusinessEntityId(
          getState()
        )}/configuration/on-hold-booking/${id}`
      )
    ).data
);

export const createOrUpdateOnHoldBookingConfiguration = createAsyncThunk<
  OnHoldBookingConfiguration,
  OnHoldBookingConfigurationPayload
>(
  'onHoldBookingConfiguration/createOrUpdateOnHoldBookingConfiguration',
  async ({ id, ...rest }, { getState }) =>
    (
      await (id ? api.put : api.post)<OnHoldBookingConfiguration>(
        `/owners/${selectCurrentBusinessEntityId(
          getState()
        )}/configuration/on-hold-booking${id ? `/${id}` : ''}`,
        rest
      )
    ).data
);

export const deleteOnHoldBookingConfigurations = createAsyncThunk<
  void,
  Array<string>
>(
  'onHoldBookingConfiguration/deleteOnHoldBookingConfigurations',
  async (ids, { getState, dispatch }) => {
    await api.post(
      `/owners/${selectCurrentBusinessEntityId(
        getState()
      )}/configuration/on-hold-booking/bulk-delete`,
      { ids }
    );
    await dispatch(getOnHoldBookingConfigurations()).unwrap();
  }
);
