import {
  OnHoldBookingConfiguration,
  OnHoldBookingConfigurationSearchFormValues,
} from 'dto/onHoldBookingConfiguration';
import { createReducer } from '@reduxjs/toolkit';
import {
  getOnHoldBookingConfiguration,
  getOnHoldBookingConfigurations,
  clearOnHoldBookingConfiguration,
  setOnHoldBookingConfigurationFilter,
  clearOnHoldBookingConfigurations,
} from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationActions';
import { Pagination } from '@fleet/shared/dto/pagination';

export interface OnHoldBookingState {
  list?: Pagination<OnHoldBookingConfiguration>;
  filter: Partial<OnHoldBookingConfigurationSearchFormValues>;
  current?: OnHoldBookingConfiguration;
}

const initialState: OnHoldBookingState = {
  filter: {},
};

export const onHoldBookingConfigurationReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(clearOnHoldBookingConfiguration, (state) => {
        state.current = undefined;
      })
      .addCase(setOnHoldBookingConfigurationFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(clearOnHoldBookingConfigurations, (state) => {
        state.list = undefined;
      })
      .addCase(getOnHoldBookingConfigurations.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(getOnHoldBookingConfiguration.fulfilled, (state, action) => {
        state.current = action.payload;
      });
  }
);
