import { createReducer } from '@reduxjs/toolkit';
import {
  getBusinessEntities,
  getCarriers,
  getClassificationGroups,
  getCountries,
} from 'features/classification/classificationActions';
import {
  BusinessEntity,
  ClassificationGroup,
  ClassificationKey,
} from 'dto/classification';
import { Classifier } from '@fleet/shared/dto/classifier';

type ClassificationState = Record<ClassificationKey, Array<Classifier>> & {
  [ClassificationGroup.BUSINESS_ENTITY]: Array<BusinessEntity>;
  [ClassificationGroup.CARRIER]: Array<Classifier<number>>;
  [ClassificationGroup.COUNTRY]: Array<Classifier<number>>;
};

const initialState = {} as ClassificationState;

export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassificationGroups.fulfilled, (state, action) => {
      action.payload.forEach((data) => (state[data.id] = data.classifications));
    })
    .addCase(getBusinessEntities.fulfilled, (state, action) => {
      state.BUSINESS_ENTITY = action.payload;
    })
    .addCase(getCarriers.fulfilled, (state, action) => {
      state.CARRIER = action.payload;
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.COUNTRY = action.payload;
    });
});
