import { createSelector, selector } from 'store/utils';

export const selectBookingFees = selector((state) => state.bookingFee.list);
export const bookingFeesSelector = createSelector(selectBookingFees);

export const selectBookingFeesFilter = selector(
  (state) => state.bookingFee.filter
);
export const bookingFeesFilterSelector = createSelector(
  selectBookingFeesFilter
);

export const selectCurrentBookingFee = selector(
  (state) => state.bookingFee.current
);
export const currentBookingFeeSelector = createSelector(
  selectCurrentBookingFee
);
