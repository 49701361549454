import { useCallback } from 'react';
import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns';

export const useValidAccessor = <T extends Date | string>() =>
  useCallback((row: { validityPeriod: { from: T; to?: T } }) => {
    if (!row?.validityPeriod) {
      return;
    }

    const { from, to } = row.validityPeriod;
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const [validFromDate, validToDate] = [from, to]
      .filter(Boolean)
      .map((str) => parseISO(str! as string));

    const validityStarted =
      isSameDay(validFromDate, now) || isAfter(now, validFromDate);
    const validityActive =
      !validToDate || isSameDay(validToDate, now) || isBefore(now, validToDate);

    return validityStarted && validityActive;
  }, []);
