import type { FC } from 'react';
import { SelectField, SelectFieldProps } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';

interface OwnerFieldProps extends Partial<SelectFieldProps<number, false>> {}

export const OwnerField: FC<OwnerFieldProps> = (props: OwnerFieldProps) => {
  const businessEntities = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );

  return (
    <SelectField
      name="ownerId"
      label={<TransField i18nKey="owner" />}
      options={businessEntities}
      required
      {...props}
    />
  );
};
