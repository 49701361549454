import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransNav = createTrans({
  bookingFee: <Trans i18nKey="nav.bookingFee" defaults="Booking fee" />,
  onHoldBooking: (
    <Trans i18nKey="nav.onHoldBooking" defaults="On-hold booking" />
  ),
  ticketTexts: <Trans i18nKey="nav.ticketTexts" defaults="Ticket texts" />,
  timeToLive: <Trans i18nKey="nav.timeToLive" defaults="Booking TTL" />,
});
